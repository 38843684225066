<div class="z-50 w-full h-16 bg-white border-t border-gray-200">
  <div class="grid h-full max-w-screen-sm grid-cols-5">
    <!-- Explore button -->
    <button
      data-tooltip-target="tooltip-explore"
      type="button"
      class="inline-flex flex-col items-center justify-center p-4"
      routerLink="/explore"
      routerLinkActive="border-solid border-t-2 border-blue-500"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        stroke-width="1.5"
        stroke="currentColor"
        class="w-6 h-6"
        routerLinkActive="!stroke-blue-500"
      >
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          d="m21 21-5.197-5.197m0 0A7.5 7.5 0 1 0 5.196 5.196a7.5 7.5 0 0 0 10.607 10.607Z"
        />
      </svg>
      <span class="sr-only">Utama</span>
    </button>
    <div
      id="tooltip-explore"
      role="tooltip"
      class="absolute z-10 invisible inline-block px-3 py-2 text-sm font-medium text-white transition-opacity duration-300 bg-gray-900 rounded-lg shadow-sm opacity-0 tooltip"
    >
      Utama
      <div class="tooltip-arrow" data-popper-arrow></div>
    </div>
    <!-- Campaign button -->
    <button
      data-tooltip-target="tooltip-campaign"
      type="button"
      class="inline-flex flex-col items-center justify-center p-4"
      routerLink="/campaign"
      routerLinkActive="border-solid border-t-2 border-blue-500"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        fill="none"
        stroke-width="1.5"
        stroke="currentColor"
        class="w-6 h-6 stroke-slate-500"
        routerLinkActive="!stroke-blue-500"
      >
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          d="M3 3v1.5M3 21v-6m0 0 2.77-.693a9 9 0 0 1 6.208.682l.108.054a9 9 0 0 0 6.086.71l3.114-.732a48.524 48.524 0 0 1-.005-10.499l-3.11.732a9 9 0 0 1-6.085-.711l-.108-.054a9 9 0 0 0-6.208-.682L3 4.5M3 15V4.5"
        />
      </svg>
      <span class="sr-only">Campaign</span>
    </button>
    <div
      id="tooltip-campaign"
      role="tooltip"
      class="absolute z-10 invisible inline-block px-3 py-2 text-sm font-medium text-white transition-opacity duration-300 bg-gray-900 rounded-lg shadow-sm opacity-0 tooltip"
    >
      Campaign
      <div class="tooltip-arrow" data-popper-arrow></div>
    </div>
    <!-- Report button -->
    <button
      data-tooltip-target="tooltip-report"
      type="button"
      class="inline-flex flex-col items-center justify-center p-4 hover:bg-gray-50"
      routerLink="/report"
      routerLinkActive="border-solid border-t-2 border-blue-500"
      (click)="goToCamera()"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        stroke-width="1.5"
        stroke="currentColor"
        class="w-6 h-6"
        routerLinkActive="!stroke-blue-500"
      >
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          d="M12 4.5v15m7.5-7.5h-15"
        />
      </svg>
      <span class="sr-only">Kirim Laporan</span>
    </button>
    <div
      id="tooltip-report"
      role="tooltip"
      class="absolute z-10 invisible inline-block px-3 py-2 text-sm font-medium text-white transition-opacity duration-300 bg-gray-900 rounded-lg shadow-sm opacity-0 tooltip"
    >
      Kirim Laporan
      <div class="tooltip-arrow" data-popper-arrow></div>
    </div>
    <!-- Map button -->
    <button
      data-tooltip-target="tooltip-map"
      type="button"
      class="inline-flex flex-col items-center justify-center p-4"
      routerLink="/map"
      routerLinkActive="border-solid border-t-2 border-blue-500"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        fill="none"
        stroke-width="1.5"
        stroke="currentColor"
        class="w-6 h-6"
        routerLinkActive="!stroke-blue-500"
      >
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          d="M15 10.5a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z"
        />
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          d="M19.5 10.5c0 7.142-7.5 11.25-7.5 11.25S4.5 17.642 4.5 10.5a7.5 7.5 0 1 1 15 0Z"
        />
      </svg>
      <span class="sr-only">Peta</span>
    </button>
    <div
      id="tooltip-map"
      role="tooltip"
      class="absolute z-10 invisible inline-block px-3 py-2 text-sm font-medium text-white transition-opacity duration-300 bg-gray-900 rounded-lg shadow-sm opacity-0 tooltip"
    >
      Peta
      <div class="tooltip-arrow" data-popper-arrow></div>
    </div>
    <!-- Profile button -->
    <button
      data-tooltip-target="tooltip-profile"
      type="button"
      class="inline-flex flex-col items-center justify-center p-4"
      routerLink="/profile"
      routerLinkActive="border-solid border-t-2 border-blue-500"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        fill="none"
        stroke-width="1.5"
        stroke="currentColor"
        class="w-6 h-6"
        routerLinkActive="!stroke-blue-500"
      >
        <path
          d="M7.61713 8.71233L10.8222 6.38373C11.174 6.12735 11.6087 5.98543 12.065 6.0008C13.1764 6.02813 14.1524 6.75668 14.4919 7.82036C14.6782 8.40431 14.8481 8.79836 15.0017 9.0025C15.914 10.2155 17.3655 11 19.0002 11V13C16.8255 13 14.8825 12.0083 13.5986 10.4526L12.901 14.4085L14.9621 16.138L17.1853 22.246L15.3059 22.93L13.266 17.3256L9.87576 14.4808C9.32821 14.0382 9.03139 13.3192 9.16231 12.5767L9.67091 9.6923L8.99407 10.1841L6.86706 13.1116L5.24902 11.9361L7.60016 8.7L7.61713 8.71233ZM13.5002 5.5C12.3956 5.5 11.5002 4.60457 11.5002 3.5C11.5002 2.39543 12.3956 1.5 13.5002 1.5C14.6047 1.5 15.5002 2.39543 15.5002 3.5C15.5002 4.60457 14.6047 5.5 13.5002 5.5ZM10.5286 18.6813L7.31465 22.5116L5.78257 21.226L8.75774 17.6803L9.50426 15.5L11.2954 17L10.5286 18.6813Z"
        ></path>
      </svg>
      <span class="sr-only">Profil</span>
    </button>
    <div
      id="tooltip-profile"
      role="tooltip"
      class="absolute z-10 invisible inline-block px-3 py-2 text-sm font-medium text-white transition-opacity duration-300 bg-gray-900 rounded-lg shadow-sm opacity-0 tooltip"
    >
      Profil
      <div class="tooltip-arrow" data-popper-arrow></div>
    </div>
  </div>
</div>
